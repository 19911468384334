













































































































// Core
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Interfaces
import { LegalPerson, NaturalPerson } from '@/store/users/interfaces'
import { Paginate } from '@/store/interfaces'
import { ElForm } from 'element-ui/types/form'
import { UsersActions } from '@/store/users/types'

// Modules
const NSUsers = namespace('usersModule')

@Component({
  name: 'DialogLegal',
})
export default class DialogLegalComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop({
    type: Object,
    default: () => ({}),
  })
  private legalPerson!: LegalPerson

  @Prop({
    type: Array,
    default: () => [],
  })
  private naturalPersons!: Paginate<NaturalPerson>

  @Prop(Function) private disconnectNaturalPerson!: (naturalPerson: NaturalPerson) => void

  @Prop(Function) private handleVisibleDialogChoosePerson!: () => void

  @Prop(Function) private handleVisibleDialogAddIndividual!: () => void

  @NSUsers.Action(UsersActions.A_UPDATE_LEGAL_PERSON)
  private updateLegalPerson!: (legalPerson: LegalPerson) => Promise<LegalPerson>

  private isExpectation: boolean = false

  private form: LegalPerson = { ...this.legalPerson }

  private rules = {
    name: [
      { required: true, message: 'Обязательное поле', trigger: 'blur' },
      { max: 200, message: 'Название организации слижком большое' },
    ],
    inn: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  public handleUpdateLegal(): void {
    const form = this.$refs.form as ElForm

    form.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.updateLegalPerson(this.form)
          .then(data => {
            this.isExpectation = false
            this._visible = false
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      }
    })
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    this._visible = false

    form.resetFields()
  }
}
